.modal-wrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(107, 114, 128, 0.5);
  z-index: 1300;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.TextArea, .TextArea.Mui-focused {
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.text-limit > span {
  margin: 0em 0.5em 0em 0.2em;
}

.footer-bar-button {
  border: none;
  background-color: transparent;
}

.EmojiPickerReact {
  z-index: 999 !important;
  position: absolute !important;
}

.card-root {
  overflow: unset !important;
}
