.fc .fc-button-primary {
  background: #2A5FF1 !important;
  border-color: #2A5FF1 !important;
}
.fc .fc-button-primary:hover {
  background: #2A5FF1 !important;
  border-color: #2A5FF1 !important;
}

.fc .fc-h-event {
  background-color: transparent !important;
}

.fc-daygrid-day-events, .fc-timegrid-col-events{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  padding-right: calc(-0.5 * var(--bs-gutter-x));
  padding-left: calc(-0.5 * var(--bs-gutter-x));
}

.fc-daygrid-event-harness {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.fc-img-event {
  width: 3.5em;
  border-radius: 5px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
  box-shadow: none !important;
}